.buttonOn {
  @apply bg-dark border-dark text-lightest;
}

.buttonOnError {
  @apply bg-error-dark border-error-dark text-error-light;
}


.buttonOff {
  @apply text-dark border-primary;
}

.buttonOffError {
  @apply text-error-dark border-error;
}

.buttonCommon {
  @apply select-none rounded-full text-sm whitespace-nowrap px-2 py-1 border-2 transition duration-100 ease-out;
  -webkit-tap-highlight-color: transparent;
  background-image: none;
}

.buttonCommon:focus {
  outline-width: 0;
}

.buttonCommon::-moz-focus-inner {
  border: 0;
}

.pillOnly {
  @apply cursor-default;
}


/* mouse */
@media(hover: hover) {
  .pillButton.buttonOff:hover {
    @apply border-dark text-dark ring-1 ring-dark ring-inset;
  }

  .pillButton.buttonOffError:hover {
    @apply border-error-dark text-error-dark ring-1 ring-error-dark ring-inset;
  }

  .pillButton:active {
    @apply opacity-50;
  }

  .pillButton.buttonOn:hover {
    @apply bg-primary border-primary;
  }

  .pillButton.buttonOnError:hover {
    @apply bg-error border-error;
  }
}
