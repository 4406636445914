.input::placeholder {
  color: white;
  opacity: 0.5
}




/* clears the 'X' from Chrome */
.input::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

.input::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

.input::-webkit-search-decoration,
.input::-webkit-search-cancel-button,
.input::-webkit-search-results-button,
.input::-webkit-search-results-decoration {
  display: none;
}
